export const csCenter = {
  name: 'csCenter',
  path: '/cscenter',
  component: () => import('@/views/member/cscenter'),
  redirect: {
    name: 'qna'
  },
  meta: {
    layout: 'default',
    text: 'front.common.cscenter',
    gnb: true,
    sub: false
  },
  children: [
    {
      name: 'notice',
      path: '/notice',
      component: () => import('@/views/member/cscenter/notice'),
      redirect: {
        name: 'noticeList'
      },
      meta: {
        text: 'front.common.notice',
        gnbSub: false,
        lnbClass: 'csmenu2',
        lnb: true,
        lnbChildren: false
      },
      children: [
        {
          name: 'noticeList',
          path: '/notice/list',
          component: () => import('@/views/member/cscenter/notice/list'),
          meta: {
            text: 'front.common.notice',
            gnbSub: false
          }
        },
        {
          name: 'noticeRead',
          path: '/notice/read/:boardIdx',
          component: () => import('@/views/member/cscenter/notice/read'),
          meta: {
            text: 'front.common.notice',
            gnbSub: false
          }
        }
      ]
    },
    {
      name: 'qna',
      path: '/qna',
      component: () => import('@/views/member/cscenter/qna'),
      redirect: {
        name: 'qnaList'
      },
      meta: {
        text: 'front.common.qna',
        gnbSub: false,
        lnbClass: 'csmenu1',
        lnb: true,
        lnbChildren: false
      },
      children: [
        {
          name: 'qnaList',
          path: '/qna/list',
          component: () => import('@/views/member/cscenter/qna/list'),
          meta: {
            text: 'front.common.qna',
            gnbSub: false
          }
        },
        {
          name: 'qnaWrite',
          path: '/qna/write',
          component: () => import('@/views/member/cscenter/qna/write'),
          meta: {
            text: 'front.common.qna',
            gnbSub: false
          }
        },
        {
          name: 'qnaRead',
          path: '/qna/read/:boardIdx',
          component: () => import('@/views/member/cscenter/qna/read'),
          meta: {
            text: 'front.common.qna',
            gnbSub: false
          }
        }
      ]
    },
    {
      name: 'faq',
      path: '/faq',
      component: () => import('@/views/member/cscenter/faq'),
      redirect: {
        name: 'faqList'
      },
      meta: {
        text: 'front.common.faq',
        lnbClass: 'csmenu3',
        lnb: true,
        lnbChildren: false
      },
      children: [
        {
          name: 'faqList',
          path: '/faq/list',
          component: () => import('@/views/member/cscenter/faq/list'),
          meta: {
            text: 'front.common.faq',
            gnbSub: false
          }
        }
      ]
    },
    {
      name: 'accountQna',
      path: '/accountqna',
      meta: {
        text: 'front.board.bankAccountQna',
        lnbClass: 'csmenu4',
        lnb: false,
        lnbChildren: false
      }
    },
    {
      name: 'bettingGuide',
      path: '/bettingguide',
      component: () => import('@/views/member/cscenter/bettingGuide'),
      redirect: {
        name: 'bettingGuideSport'
      },
      meta: {
        text: 'front.common.bettingGuide',
        lnbClass: 'csmenu5',
        lnb: false,
        lnbChildren: false
      },
      children: [
        {
          name: 'bettingGuideSport',
          path: '/bettingguide/sport',
          component: () => import('@/views/member/cscenter/bettingGuide/sport'),
          meta: {
            text: '스포츠게임',
            lnb: true
          }
        }

      ]
    },
    {
      name: 'gameGuide',
      path: '/gameguide',
      meta: {
        text: 'front.common.gameguid',
        gnbSub: false,
        lnb: false
      }
    }
  ]
}
