export const result = {
  name: 'result',
  path: '/result',
  component: () => import('@/views/member/result'),
  redirect: {
    name: 'resultSport'
  },
  meta: {
    layout: 'default',
    text: '경기결과',
    gnb: true,
    sub: false
  },
  children: [
    {
      name: 'resultSport',
      path: '/result/sport',
      component: () => import('@/views/member/result/sport'),
      redirect: {
        name: 'resultSportHandy'
      },
      meta: {
        text: '스포츠',
        lnbClass: 'csmenu14',
        gnbSub: false,
        lnb: true,
        lnbChildren: true
      },
      children: [
        {
          name: 'resultSportWinLoose',
          path: 'winloose',
          component: () => import('@/views/member/result/sport/winloose'),
          meta: {
            text: '승무패',
            lnb: true
          }
        },
        {
          name: 'resultSportHandy',
          path: 'handy',
          component: () => import('@/views/member/result/sport/handy'),
          meta: {
            text: '핸디캡',
            lnb: true
          }
        },
        {
          name: 'resultSportUnderover',
          path: 'handy',
          component: () => import('@/views/member/result/sport/underover'),
          meta: {
            text: '언오버',
            lnb: true
          }
        },
        {
          name: 'resultSportSpecial',
          path: 'handy',
          component: () => import('@/views/member/result/sport/special'),
          meta: {
            text: '스페셜',
            lnb: true
          }
        }
      ]
    },
    {
      name: 'resultMini',
      path: '/result/mini',
      component: () => import('@/views/member/result/mini'),
      redirect: {
        name: 'resultSportMini'
      },
      meta: {
        text: '미니게임',
        lnbClass: 'csmenu15',
        gnbSub: false,
        lnb: true,
        lnbChildren: false
      },
      children: [
        {
          name: 'resultSportMini',
          path: 'mini',
          component: () => import('@/views/member/result/mini'),
          meta: {
            text: '승무패',
            lnb: true
          }
        }
      ]
    }
  ]
}
