<template>
  <transition name="fade">
    <new-message-mobile v-if="isUnReadMessage" />
  </transition>

  <transition name="fade">
    <TMmodal v-show="TMOpen" :isOpen="TMOpen" :type="'header'" @close="onCloseTM"/>
  </transition>

  <template v-if="type != 'empty'">
    <div class="fx">
      <ul class="fxhead">
        <li class="home" :class="{'out': side}"><a @click="side=!side"><em></em><span>menu</span></a></li>

        <li class="in" :class="{'on': $route.meta.gnbMenu === 'charge'}"><a @click="goPageByName('charge')"><em></em><span>{{$t('front.common.deposit')}}</span></a></li>
        <li class="outs" :class="{'on': $route.meta.gnbMenu === 'exchange'}"><a @click="goPageByName('exchange')"><em></em><span>{{$t('front.common.withdrawal')}}</span></a></li>
        <li class="point" :class="{'on': $route.meta.gnbMenu === 'point'}"><a @click="goPageByName('point')"><em></em><span>{{$t('front.common.point')}}</span></a></li>
        <li class="cs" :class="{'on': $route.meta.gnbMenu === 'cs'}"><a @click="goPageByName('qnaList')"><em></em><span>{{$t('front.common.cscenter')}}</span></a></li>
        <!--li class="msg" :class="{'on': $route.meta.gnbMenu === 'msg'}"><a @click="goPageByName('msg')"><em></em><span>{{$t('front.mypage.message')}}</span></a></li>
        <li class="home" :class="{'on': $route.meta.gnbMenu === 'betList'}"><a @click="goPageByName('betList')"><em></em><span>{{$t('front.mypage.betList')}}</span></a></li>
        <li class="my" :class="{'on': $route.meta.gnbMenu === 'myinfo'}"><a @click="goPageByName('myinfo')"><em></em><span>{{$t('front.common.mypage')}}</span></a></li>
        <li class="ques" :class="{'on': $route.meta.gnbMenu === 'cs'}"><a @click="goPageByName('qnaList')"><em></em><span>{{$t('front.common.cscenter')}}</span></a></li>
        <li class="my" :class="{'on': $route.meta.gnbMenu === 'myinfo'}"><a @click="goPageByName('myinfo')"><em></em><span>{{$t('front.common.mypage')}}</span></a></li>
        <li class="home on"><a @click="goPageByName('mobileMain')"><span>홈</span></a></li-->
      </ul>
    </div>

    <header>
      <!--div class="noticeWrap">
        <div class="notice"><em>{{$t('front.common.notices')}}</em><span>{{$t('front.header.msg')}}</span></div>
        <div class="langbtn" @click="openLang()">
          <button class="langdropbtn" >
            <template v-if="$i18n.locale === 'ko'">
              <img src="@/assets_mobile/img/korea.png" /><span class="langsp">KO</span>
            </template>
            <template v-if="$i18n.locale === 'en'">
              <img src="@/assets/img/eng.png" /><span class="langsp">EN</span>
            </template>
            <template v-if="$i18n.locale === 'vn'">
              <img src="@/assets/img/vn.svg" /><span class="langsp">VN</span>
            </template>
            <img class="langbtnb" src="@/assets_mobile/img/langbtn.png" />
          </button>
          <div class="langdropdown" v-if="isOpenLang">
            <a href="#" :class="{'on': $i18n.locale === 'ko'}" @click="changeLocale('ko')">KR</a>
            <a href="#" :class="{'on': $i18n.locale === 'en'}" @click="changeLocale('en')">EN</a>
            <a href="#" :class="{'on': $i18n.locale === 'vn'}" @click="changeLocale('vn')">VN</a>
          </div>
        </div>
      </div-->
      <div class="header">
        <h1 class="logo"><a @click="goPageByName('mobileMain')"><img src="@/assets/img/soltlogo.png" /></a></h1>
        <div class="logininfowrap" v-if="userData">
          <a class="myin" @click="goPageByName('info')"><!--img src="@/assets_mobile/img/icon_m_user.svg" /-->{{userData.memNick}}</a>
          <ul class="mym">
            <li class="myc" @click="goPageByName('charge')">
               <span>
                  <em>{{$t('front.cash.haveCash')}}</em>
                  {{userData.cashAmt ? thousand(userData.cashAmt.toString()): 0}}
               </span>
               <i>+</i>
            </li>
            <li class="myc" @click="goPageByName('point')">
               <span>
                  <em>{{$t('front.cash.nav4')}}</em>
                  {{thousand(userData.pointAmt ? userData.pointAmt.toString() : '0')}}
               </span>
               <i>+</i>
            </li>
            <!--li class="myp" @click="goPageByName('point')"><img src="@/assets_mobile/img/mypicon.png" /><span>{{userData.pointAmt ? thousand(userData.pointAmt.toString()): 0}}</span></li>
            <li class="myg"><img src="@/assets_mobile/img/mygicon.png" /><span>{{thousand(coinAmt ? coinAmt.toString(): '0')}}</span></li-->
          </ul>
          <a @click="goPageByName('msgMain')" class="mymsg"><img src="@/assets_mobile/img/message_off.svg" /><span class="mgsn">{{thousand(unReadMessageCount ? unReadMessageCount.toString() : '0')}}</span></a>
        </div>
        <div class="logininfowrap" v-if="!userData">
          <a class="mloginbtn" @click="goPageByName('mobileJoin')">JOIN</a>
          <a class="mloginbtn" @click="goPageByName('mobileLogin')">LOGIN</a>
        </div>
      </div>
    </header>
    <!--a @click="goBack" class="back"><img src="@/assets_mobile/img/back.png" /></a>
    <a class="sideMenu" @click="side=!side" :class="{'out': side}"><em></em></a-->
    <transition name="side">
       <nav v-if="side">
         <div class="nav-wrap">
            <div class="nav-head">
               <div class="header">
                 <h1 class="logo"><a @click="goPageByNamez('mobileMain')"><img src="@/assets/img/soltlogo.png" /></a></h1>
                 <!--a v-if="userData" @click="goPageByNamez('msgMain')" class="mmsg"><img src="@/assets_mobile/img/message_off.png" /><span class="mgsn">{{thousand(unReadMessageCount ? unReadMessageCount.toString() : '0')}}</span></a>
                 <ul class="minfo" v-if="userData">
                   <li>
                      <span>{{userData.memNick}}</span>
                   </li>
                   <li>
                      <em>₩</em>
                      <span>{{userData.cashAmt ? thousand(userData.cashAmt.toString()): 0}}</span>
                   </li>
                   <li>
                      <span>{{userData.cashAmt ? thousand(userData.cashAmt.toString()): 0}}</span>
                   </li>
                   <li @click="goPageByName('point')">
                      <em>P</em>
                      <span>{{userData.pointAmt ? thousand(userData.pointAmt.toString()): 0}}</span>
                   </li>
                 </ul>
                 <div v-if="!userData">
                   <a class="mloginbtn" @click="goPageByNamez('mobileJoin')">JOIN</a>
                   <a class="mloginbtn on" @click.stop="goPageByNamez('mobileLogin')">LOGIN</a>
                 </div-->
               </div>
               <a class="sideMenu" @click="side=!side" :class="{'in': side}"><img src="@/assets/img/icon_x.svg" /></a>
            </div>
            <div class="nav-con">
               <ul>
                  <li>
                      <a @click="goPageByNamez('charge')">
                         <img src="@/assets/img/left/icon_left_in.svg" />
                         <span>{{$t('front.common.deposit')}}</span>
                      </a>
                  </li>
                  <li>
                      <a @click="goPageByNamez('exchange')">
                         <img src="@/assets/img/left/icon_left_out.svg" />
                         <span>{{$t('front.common.withdrawal')}}</span>
                      </a>
                  </li>
               </ul>
               <ul>
                  <li>
                      <a @click="goPageByNamez('noticeList')">
                         <img src="@/assets/img/left/icon_left_notice.svg" />
                         <span>{{$t('front.common.notice')}}</span>
                      </a>
                  </li>
                  <li>
                      <a @click="goPageByNamez('qnaList')">
                         <img src="@/assets/img/left/icon_left_ask.svg" />
                         <span>{{$t('front.common.qna')}}</span>
                      </a>
                  </li>
                  <li>
                      <a @click="goPageByNamez('faq')">
                         <img src="@/assets/img/left/icon_left_faq.svg" />
                         <span>{{$t('front.common.faq')}}</span>
                      </a>
                  </li>
               </ul>
               <ul>
                  <li>
                      <a @click="goPageByNamez('betList')">
                         <img src="@/assets/img/left/icon_left_bet.svg" />
                         <span>{{$t('front.mypage.betList')}}</span>
                      </a>
                  </li>
                  <li>
                      <a @click="goPageByNamez('point')">
                         <img src="@/assets/img/left/icon_left_point.svg" />
                         <span>{{$t('front.common.point')}}</span>
                      </a>
                  </li>
                  <li>
                      <a @click="goPageByNamez('msg')">
                         <img src="@/assets/img/left/icon_left_msg.svg" />
                         <span>{{$t('front.mypage.message')}}</span>
                      </a>
                  </li>
                  <!-- <li>
                      <a @click="goPageByNamez('info')">
                         <img src="@/assets/img/left/icon_left_info.svg" />
                         <span>{{$t('front.mypage.memInfoModify')}}</span>
                      </a>
                  </li> -->
                  <li v-if="userData">
                      <a :href="'/JG5'" target="_blank" v-if="userData.isPartnerMember && userData.isPartnerMember === 'Y'">
                         <img src="@/assets/img/left/icon_left_info.svg" />
                         <span>{{$t('front.common.partner')}}</span>
                      </a>
                  </li>
               </ul>
               <ul>
                  <!-- <li><a @click="onClickTM()"><img src="@/assets/img/left/icon_left_out.svg" /><span>통합머니 전환</span></a></li> -->
               </ul>
               <ul>
                  <li>
                      <a @click="signOut()" class="out">
                         <img src="@/assets/img/icon_logout.svg" />
                         <span>{{$t('front.common.logout')}}</span>
                      </a>
                  </li>
               </ul>
            </div>
          </div>
       </nav>
    </transition>
  </template>
</template>

<script>
import { mapState } from 'vuex'
import NewMessageMobile from '@/components/member/MessageMobile.vue'
import TMmodal from '@/components/common/TotalMoney.vue'

export default {
  name: 'Header',
  components: { TMmodal, NewMessageMobile },
  props: [
    'type'
  ],
  data () {
    return {
      isOpenLang: false,
      side: false,
      TMOpen: false
    }
  },
  computed: {
    ...mapState([
      'userData',
      'unReadMessageCount'
    ]),
    isUnReadMessage () {
      return this.unReadMessageCount && this.unReadMessageCount && this.unReadMessageCount !== '0' && this.$route.name.indexOf('message') === -1 && this.$route.name.indexOf('msg') === -1
    }
  },
  methods: {
    async onClickTM () {
      const confirmMessage = '통합머니 전환을 진행하시겠습니까?'
      const confirmResult = await this.onConfirm(confirmMessage)
      if (confirmResult) {
        this.TMOpen = true
      }
    },
    onCloseTM () {
      this.TMOpen = false
    },
    openLang () {
      this.isOpenLang = !this.isOpenLang
    },
    onLogOut () {
      this.$root.signOut()
      this.$router.push({ name: 'main' })
      // location.reload()
    },
    goPageByNamez (name, params) {
      const query = { name: name }
      if (params) {
        query.params = params
      }
      this.$router.push(query)
      this.side = !this.side
    }
  }
}
</script>

<style scoped src="@/styles_mobile/header.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/swiper.min.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
