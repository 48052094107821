export const liveCasino = {
  name: 'liveCasino',
  path: '/livecasino',
  meta: {
    layout: 'default',
    text: '라이브카지노',
    gnb: true,
    sub: true
  },
  children: [
    {
      name: 'evolutionGaming',
      path: '/evolution',
      meta: {
        text: '에볼루션 게이밍',
        gnbSub: true,
        game: true,
        gameName: 'evolution',
        gameCode: 1,
        class: 'evo'
      }
    },
    {
      name: 'asiagaming',
      path: '/asiagaming',
      meta: {
        text: '아시아 게이밍',
        gnbSub: true,
        game: true,
        gameName: 'asiagaming',
        gameCode: 5,
        class: 'asia'
      }
    },
    {
      name: 'pragmatic',
      path: '/pragmatic',
      meta: {
        text: '프라그마틱',
        gnbSub: true,
        game: true,
        gameName: 'pragmaticplay',
        gameCode: 10,
        class: 'prag'
      }
    },
    {
      name: 'dreamCasino',
      path: '/dreamcasino',
      meta: {
        text: '드림 카지노',
        gnbSub: true,
        game: true,
        gameName: 'dreamgaming',
        gameCode: 6,
        class: 'dg'
      }
    },
    {
      name: 'sexygaming',
      path: '/sexygaming',
      meta: {
        text: '섹시 게이밍',
        gnbSub: true,
        game: true,
        gameName: 'sexygaming',
        gameCode: 9,
        class: 'sexy'
      }
    },
    {
      name: 'biggaming',
      path: '/biggaming',
      meta: {
        text: '빅 게이밍',
        gnbSub: true,
        game: true,
        gameName: 'biggaming',
        gameCode: 2,
        class: 'big'
      }
    }
  ]
}
