export const esports = {
  name: 'esports',
  path: '/esport',
  meta: {
    layout: 'default',
    text: 'e-스포츠',
    gnb: true,
    sub: true
  },
  children: [
    {
      name: 'gamestart',
      path: '/gamestart',
      meta: {
        text: '게임시작',
        gnbSub: true,
        class: 'lol',
        game: true,
        gameName: 'esports',
        gameCode: 101
      }
    },
    {
      name: 'screendescription',
      path: '/screendescription',
      redirect: {
        name: 'gameway', params: { type: 'screendescription' }
      },
      meta: {
        text: '화면구성설명',
        gnbSub: true,
        class: 'sudden'
      }
    },
    {
      name: 'onefolderbetting',
      path: '/onefolderbetting',
      redirect: {
        name: 'gameway', params: { type: 'onefolderbetting' }
      },
      meta: {
        text: '단폴더베팅방법',
        gnbSub: true,
        class: 'overwatch'
      }
    },
    {
      name: 'twofolderbetting',
      path: '/twofolderbetting',
      redirect: {
        name: 'gameway', params: { type: 'twofolderbetting' }
      },
      meta: {
        text: '다폴더베팅방법',
        gnbSub: true,
        class: 'pubg'
      }
    }
  ]
}
