export const exchange = {
  name: 'exchangeMain',
  path: '/exchangeMain',
  component: () => import('@/views/member/exchange'),
  redirect: {
    name: 'exchange'
  },
  meta: {
    layout: 'default',
    text: '출금',
    gnb: false
  },
  children: [
    {
      name: 'exchange',
      path: '/exchange',
      component: () => import('@/views/member/exchange/exchange'),
      meta: {
        layout: 'default',
        text: 'front.cash.nav2',
        gnb: false,
        gnbSub: true,
        lnbClass: 'csmenu16',
        lnb: true,
        lnbChildren: false
      }
    },
    {
      name: 'exchangeList',
      path: '/exchangeList',
      component: () => import('@/views/member/exchange/exchangeList'),
      meta: {
        layout: 'default',
        text: '출금 내역',
        gnb: false,
        lnbClass: 'csmenu17',
        lnb: true,
        lnbChildren: false
      }
    }

  ]
}
