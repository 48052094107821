export const miniGame = {
  name: 'miniGame',
  path: '/minigame',
  component: () => import('@/views/member/minigame'),
  meta: {
    layout: 'default',
    text: '미니게임',
    gnb: true,
    sub: true
  },
  children: [
    {
      name: 'powerball',
      path: '/powerball',
      component: () => import('@/views/member/minigame/powerball'),
      meta: {
        text: '파워볼',
        gnbSub: true,
        class: 'powerball',
        gameName: 'powerball',
        gameCode: 'mg'
      }
    },
    {
      name: 'powerladder',
      path: '/powerladder',
      component: () => import('@/views/member/minigame/powerladder'),
      meta: {
        text: '파워사다리',
        gnbSub: true,
        class: 'powerladder',
        gameName: 'powerladder'
      }
    },
    {
      name: 'speedkino',
      path: '/speedkino',
      component: () => import('@/views/member/minigame/speedkino'),
      meta: {
        text: '스피드키노',
        gnbSub: true,
        class: 'speedkino',
        gameName: 'speedkino'
      }
    },
    {
      name: 'kinoladder',
      path: '/kinoladder',
      meta: {
        text: '키노사다리',
        gnbSub: true,
        class: 'kinoladder'
      }
    }
  ]
}
