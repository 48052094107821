export const charge = {
  name: 'chargeMain',
  path: '/chargeMain',
  component: () => import('@/views/member/charge'),
  redirect: {
    name: 'charge'
  },
  meta: {
    layout: 'default',
    text: '입금',
    gnb: false
  },
  children: [
    {
      name: 'charge',
      path: '/charge',
      component: () => import('@/views/member/charge/charge'),
      meta: {
        layout: 'default',
        text: 'front.cash.nav1',
        gnb: false,
        gnbSub: true,
        lnbClass: 'csmenu16',
        lnb: true,
        lnbChildren: false
      }
    },
    {
      name: 'chargeList',
      path: '/chargeList',
      component: () => import('@/views/member/charge/chargeList'),
      meta: {
        layout: 'default',
        text: '입금 내역',
        gnb: false,
        lnbClass: 'csmenu17',
        lnb: true,
        lnbChildren: false
      }
    }

  ]
}
