<template>
  <vue-final-modal v-slot="{ close }">
    <div class="loginmodal">
      <div class="art" :class="level"><!-- agreeart warningart -->
        <!--span class="img"></span-->
        <p v-html="$t(code)"></p>
        <div class="btn">
          <template v-if="type == 'confirm'">
            <button class="yes" @click="$emit('confirm', 'yes', close)">{{$t('front.button.yes')}}</button>
            <button class="no" @click="$emit('confirm', 'no', close)">{{$t('front.button.no')}}</button>
          </template>

          <template v-if="type == 'check'">
            <button class="yes" @click="$emit('check', 'ok', close)">{{$t('front.button.confirm')}}</button>
          </template>
        </div>
        <button class="closeart" @click="onClose(close)"><img src="@/assets/img/icon_x.svg" /></button>
      </div>
    </div>
  </vue-final-modal>

</template>

<script>

export default {
  name: 'Alert',
  props: {
    code: {
      type: String
    },
    type: {
      type: String
    },
    level: {
      type: String,
      default: 'warningart'
    }
  },
  data () {
    return {
      lang: 'ko',
      result: ''
    }
  },
  methods: {
    onClose (close) {
      close()
      // this.$emit('close', this.type, this.result)
    }
  }
}
</script>

<style scoped>
button {cursor: pointer}
.art {position: relative;width: 498px;margin: 100px auto 0;padding: 50px 0 28px;text-align: center;border-radius: 2px;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);background-color: #201f1f;}
.art > img {height: 80px;margin-bottom: 30px;}
.art h1 {font-size: 30px;font-weight: bold;}
.art p {font-size: 18px;color: #fff;line-height: 1.5em;}
.art .btn {display: flex;justify-content: center;gap: 15px;}
.art .yes, .art .no {border: 0;box-sizing: border-box;width: 184px;height: 42px;font-size: 16px;margin-top: 30px;text-shadow: 0 1px 3px rgba(0, 0, 0, 0.98);}
.art .img {display: block;margin: 0 auto;margin-bottom: 30px;}
.agreeart .img {width: 90px;height: 65px;background: url('~@/assets/img/agreeart_green.svg')no-repeat;background-position: center;}
.agreeart>h1 {color: #e5972d;}
.agreeart .yes {color: #fff;background-image: linear-gradient(to right, #93d46d, #ced74b);}
.agreeart .no {color: #fff;background-image: linear-gradient(to bottom, #a5a5a5, #535353);}
.warningart .img {width: 69px;height: 64px;background: url('~@/assets/img/warningart.svg')no-repeat;background-position: center;}
.warningart>h1 {color: #e1423c;}
.warningart .yes {color: #fff;background-image: linear-gradient(to right, #93d46d, #ced74b);}
.warningart .no {color: #fff;background-image: linear-gradient(to bottom, #a5a5a5, #535353);}
.closeart {background: none;border: 0;padding: 0;position: absolute;right: 7px;top: 7px;}
.closeart img {height: 30px;}
</style>
<style scoped src="@/styles/header.css"></style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
